import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useUserStore } from "@/stores";

import { Root } from "@/components/data-table/data-table-root";
import { ContentLayout } from "@/components/layout";
import { OrdersList } from "../components/orders-list";

import { OrdersActionProvider, OrdersSelectedProvider } from "../contexts";
import { OrdersFilterSchema } from "../types";
import { OrderStatus } from "@/@core/services/types";

export const Order = () => {
  const { user } = useUserStore();
  const form = useForm<OrdersFilterSchema>();

  const defaultFilter = useCallback((): OrderStatus | string | null => {
    if (!user) return null;

    switch (user.type) {
      case "Expeditor":
        return "shipping";
      case "BookPrinter": // Impressor de miolo
        return "received,waiting_print,waiting_core_print,waiting_cover_print,core_print_requested,core_printing,cover_print_requested,cover_printing,cover_print_finished";
      case "CoverPrinter": // Impressor de capa
        return "received,waiting_print,waiting_core_print,waiting_cover_print,core_print_requested,core_printing,cover_print_requested,cover_printing,core_print_finished";
      case "Finisher":
        return "finishing";
      default:
        return null;
    }
  }, [user]);

  if (!user) return null;

  return (
    <ContentLayout title="Pedidos">
      <Root
        filterForm={form}
        defaultValues={{
          filters: {
            dateFrom: "2024-04-10 13:00",
            orderStatus: defaultFilter(),
          },
        }}
      >
        <OrdersSelectedProvider>
          <OrdersActionProvider>
            <OrdersList />
          </OrdersActionProvider>
        </OrdersSelectedProvider>
      </Root>
    </ContentLayout>
  );
};
