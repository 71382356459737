import { OrderStatus } from "@/@core/services/types";

type StatusOptions = {
  label: string;
  value: OrderStatus;
};

export const orderStatusOptions: StatusOptions[] = [
  {
    label: "Recebido",
    value: "received",
  },
  {
    label: "Espera Impressão",
    value: "waiting_print",
  },
  {
    label: "Espera Impressão Capa",
    value: "waiting_cover_print",
  },
  {
    label: "Espera Impressão Miolo",
    value: "waiting_core_print",
  },
  {
    label: "Impressão de Miolo Solicitada",
    value: "core_print_requested",
  },
  {
    label: "Imprimindo Miolo",
    value: "core_printing",
  },
  {
    label: "Impressão de Miolo Finalizada",
    value: "core_print_finished",
  },
  {
    label: "Impressão de Capa Solicitada",
    value: "cover_print_requested",
  },
  {
    label: "Imprimindo Capa",
    value: "cover_printing",
  },
  {
    label: "Impressão de Capa Finalizada",
    value: "cover_print_finished",
  },
  {
    label: "Acabamento",
    value: "finishing",
  },
  {
    label: "Expedição",
    value: "shipping",
  },
  {
    label: "Finalizado",
    value: "finished",
  },
  {
    label: "Cancelado",
    value: "canceled",
  },
  {
    label: "Falhou",
    value: "failed",
  },
];

export const orderTypeOptions = [
  {
    label: "Prioridade",
    value: "Priority",
  },
  {
    label: "Site",
    value: "Site",
  },
  {
    label: "Corporativo",
    value: "Corporate",
  },
  {
    label: "Refaz",
    value: "Reprint",
  },
];

export const statusType = {
  received: "Recebido",
  waiting_print: "Espera Impressão",
  waiting_cover_print: "Espera Impressão Capa",
  waiting_core_print: "Espera Impressão Miolo",
  core_print_finished: "Impressão de Miolo Finalizada",
  core_print_requested: "Impressão de Miolo Solicitada",
  core_printing: "Imprimindo Miolo",
  cover_print_finished: "Impressão de Capa Finalizada",
  cover_print_requested: "Impressão de Capa Solicitada",
  cover_printing: "Imprimindo Capa",
  finished: "Finalizado",
  finishing: "Acabamento",
  shipping: "Expedição",
  canceled: "Cancelado",
  failed: "Falhou",
};

export const orderType = {
  priority: "Prioridade",
  site: "Site",
  corporate: "Corporativo",
  reprint: "Refaz",
};
