import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/cn";

const badgeVariants = cva(
  "inline-flex items-center rounded-md border px-2 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default:
          "border-transparent bg-primary text-primary-foreground hover:bg-primary/80",
        secondary:
          "border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80",
        destructive:
          "border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80",
        outline: "text-foreground",
        failed: "border-transparent bg-destructive text-destructive-foreground",
        pending: "border-transparent bg-pending text-white",
        error: "border-transparent bg-destructive text-destructive-foreground",
        success: "border-transparent bg-success text-white",
        canceled:
          "border-transparent bg-destructive text-destructive-foreground",
        received: "border-transparent text-status-received-text bg-status-received-bg",
        waiting_print:
          "border-transparent text-status-waiting_print-text bg-status-waiting_print-bg",
        waiting_cover_print:
          "border-transparent text-status-waiting_print-text bg-status-waiting_print-bg",
        waiting_core_print:
          "border-transparent text-status-waiting_print-text bg-status-waiting_print-bg",
        core_printing: "border-transparent text-status-cover_printing-text bg-status-core_printing-bg",
        cover_printing: "border-transparent text-status-cover_printing-text bg-status-cover_printing-bg",
        core_print_finished: "border-transparent text-status-cover_printing-text bg-status-core_print_finished-bg",
        cover_print_finished: "border-transparent text-status-cover_printing-text bg-status-cover_print_finished-bg",
        core_print_requested: "border-transparent text-status-cover_printing-text bg-status-core_print_requested-bg",
        cover_print_requested: "border-transparent text-status-cover_printing-text bg-status-core_print_requested-bg",
        printing: "border-transparent text-status-printing-text bg-status-printing-bg",
        finishing: "border-transparent text-status-finisher-text bg-status-finisher-bg",
        shipping: "border-transparent text-status-expeditor-text bg-status-expeditor-bg",
        finished: "border-transparent text-status-finished-text bg-status-finished-bg",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  );
}

export { Badge, badgeVariants };
