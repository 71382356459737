import { useCallback, useContext, useRef } from "react";
import * as I from "lucide-react";

import * as T from "@/components/ui/tooltip";
import * as D from "@/components/ui/dropdown-menu";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";

import { OrdersSelectedContext } from "@/features/order/contexts";
import { Authorization, ROLES } from "@/lib/authorization";

import {
  ChangeOrderStatus,
  ChangeOrderStatusRef,
} from "@/features/order/components/order-status/admin/change-status";

import { HasNotSameStatus, HasNotSameStatusRef } from "./has-not-same-status";
import { OrderStatus, OrderStatusEnum } from "@/@core/services/types";

import { OrderAction, OrderActionRef } from "../order-action";

import {
  ActionNotAvaiableForStatus,
  ActionNotAvaiableForStatusRef,
} from "./action-not-avaiable-for-status";

import {
  ResendNotAvaiableForStatus,
  ResendNotAvaiableForStatusRef,
} from "./resend-not-avaiable-for-status";

import {
  ResendFiles,
  ResendFilesRef,
} from "../order-action/resend-files-action";

import {
  GenerateCsvConfirm,
  GenerateCsvConfirmRef,
} from "./generate-csv-confirm";
import { RenderStatusTypes } from "../../hooks/use-order-action";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/sharp-solid-svg-icons";

type Props = {
  hasOrders: boolean;
};

export const OrderOptions = ({ hasOrders }: Props) => {
  const changeOrderStatusRef = useRef<ChangeOrderStatusRef>(null);
  const hasNotSameStatusRef = useRef<HasNotSameStatusRef>(null);
  const orderActionRef = useRef<OrderActionRef>(null);
  const resendFilesRef = useRef<ResendFilesRef>(null);
  const generateCsvConfirmRef = useRef<GenerateCsvConfirmRef>(null);

  const resendNotAvaiableForStatusRef =
    useRef<ResendNotAvaiableForStatusRef>(null);
  const actionNotAvaiableForStatusRef =
    useRef<ActionNotAvaiableForStatusRef>(null);

  const { rows } = useContext(OrdersSelectedContext);

  const hasRowsSelected = rows.length > 0;

  const getActionBasedOnFirstSelected = (status: OrderStatus[]) => {
    if (status.includes(OrderStatusEnum.finishing)) {
      return {
        allRowsIsTheSame: rows.every((order) =>
          order.status.includes(OrderStatusEnum.finishing)
        ),
        status: OrderStatusEnum.finishing,
      };
    }

    if (status.includes(OrderStatusEnum.shipping)) {
      return {
        allRowsIsTheSame: rows.every((order) =>
          order.status.includes(OrderStatusEnum.shipping)
        ),
        status: OrderStatusEnum.shipping,
      };
    }

    return {
      allRowsIsTheSame: true,
      status: "printer",
    };
  };

  function handleOrderAction() {
    const statusWithNotAction = [
      OrderStatusEnum.received,
      OrderStatusEnum.canceled,
      OrderStatusEnum.failed,
      OrderStatusEnum.finished,
    ];

    const hasOneRowWithActionNotAvaiable = rows.some((order) => {
      return order.status.some((status) =>
        statusWithNotAction.includes(status as OrderStatusEnum)
      );
    });

    if (hasOneRowWithActionNotAvaiable) {
      actionNotAvaiableForStatusRef.current?.open();
      return;
    }

    const action = getActionBasedOnFirstSelected(rows[0].status);

    if (!action?.allRowsIsTheSame) {
      hasNotSameStatusRef.current?.open();
      return;
    }

    orderActionRef.current?.open(action.status as RenderStatusTypes);
  }

  function handleResendFiles() {
    const avaiableActionForStatus = [
      OrderStatusEnum.received,
      OrderStatusEnum.waiting_print,
      OrderStatusEnum.waiting_cover_print,
      OrderStatusEnum.waiting_core_print,
      OrderStatusEnum.failed,
    ];

    const hasOneRowWithStatusNotAvaiable = rows.some((order) => {
      return !order.status.some((status) =>
        avaiableActionForStatus.includes(status as OrderStatusEnum)
      );
    });

    if (hasOneRowWithStatusNotAvaiable) {
      resendNotAvaiableForStatusRef.current?.open();
      return;
    }

    resendFilesRef.current?.open();
  }

  const handleOpenCsvConfirm = useCallback(() => {
    if (!hasOrders) return;

    generateCsvConfirmRef.current?.open();
  }, [hasOrders]);

  return (
    <div className="flex items-center space-x-2 z-[3]">
      <Authorization allowedRoles={[ROLES.Administrator]}>
        <T.TooltipProvider delayDuration={200}>
          <T.Tooltip>
            <T.TooltipTrigger>
              <Badge
                variant="secondary"
                className="p-1.5 rounded-md"
                aria-disabled={!hasOrders}
                onClick={handleOpenCsvConfirm}
              >
                <I.FileSpreadsheet className="w-[18px] h-[18px]" />
              </Badge>
            </T.TooltipTrigger>
            <T.TooltipContent
              side="left"
              sideOffset={12}
              className="font-medium"
            >
              {hasOrders ? "Gerar CSV" : "Não há pedidos para gerar CSV"}
            </T.TooltipContent>
          </T.Tooltip>
        </T.TooltipProvider>
      </Authorization>

      <Authorization allowedRoles={[ROLES.Administrator]}>
        {hasRowsSelected && (
          <>
            {/* <T.TooltipProvider delayDuration={200}>
              <T.Tooltip>
                <T.TooltipTrigger>
                  <Badge
                    variant="secondary"
                    className="p-1.5 rounded-md"
                    onClick={() => changeOrderStatusRef.current?.open()}
                  >
                    <I.ArrowLeftRight className="w-[18px] h-[18px]" />
                  </Badge>
                </T.TooltipTrigger>
                <T.TooltipContent
                  side="right"
                  sideOffset={12}
                  className="font-medium"
                >
                  Alterar status
                </T.TooltipContent>
              </T.Tooltip>
            </T.TooltipProvider> */}

            <D.DropdownMenu>
              <D.DropdownMenuTrigger asChild>
                <Button variant="secondary" className="p-1.5 rounded-md">
                  <FontAwesomeIcon icon={faEllipsis} size="xl" className="mx-[1px]"/>
                </Button>
              </D.DropdownMenuTrigger>
              <D.DropdownMenuContent align="end">
                <D.DropdownMenuItem
                  className="flex items-center cursor-pointer"
                  onClick={handleOrderAction}
                >
                  <span className="ms-2">Ação do pedido</span>
                </D.DropdownMenuItem>
                <D.DropdownMenuItem
                  className="flex items-center cursor-pointer"
                  onClick={handleResendFiles}
                >
                  <span className="ms-2">Reenviar arquivos</span>
                </D.DropdownMenuItem>
              </D.DropdownMenuContent>
            </D.DropdownMenu>
          </>
        )}
      </Authorization>

      <Authorization
        allowedRoles={[
          ROLES.BookPrinter,
          ROLES.CoverPrinter,
          ROLES.Expeditor,
          ROLES.Finisher,
        ]}
      >
        <T.TooltipProvider delayDuration={500}>
          <T.Tooltip>
            <T.TooltipTrigger>
              <Badge
                variant="secondary"
                className="p-1.5 rounded-md"
                onClick={handleOrderAction}
              >
                <I.ArrowLeftRight className="w-[18px] h-[18px]" />
              </Badge>
            </T.TooltipTrigger>
            <T.TooltipContent
              side="right"
              sideOffset={12}
              className="font-medium"
            >
              Realizar ação no pedido
            </T.TooltipContent>
          </T.Tooltip>
        </T.TooltipProvider>
      </Authorization>

      <OrderAction ref={orderActionRef} />

      <ResendFiles ref={resendFilesRef} />
      <ResendNotAvaiableForStatus ref={resendNotAvaiableForStatusRef} />

      <ChangeOrderStatus ref={changeOrderStatusRef} />
      <HasNotSameStatus ref={hasNotSameStatusRef} />
      <ActionNotAvaiableForStatus ref={actionNotAvaiableForStatusRef} />

      <GenerateCsvConfirm ref={generateCsvConfirmRef} />
    </div>
  );
};
