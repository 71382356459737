import React, { useCallback, useContext, useState } from "react";
import { ColumnDef, Row } from "@tanstack/react-table";

import { DataTableOrder } from "@/components/data-table";
import { Checkbox } from "@/components/ui/checkbox";
import {
  LoadOrdersList,
  OrderStatus,
  OrderStatusEnum,
} from "@/@core/services/types";
import { formatDate } from "@/utils/format-date";

import { useUserStore } from "@/stores";
import { OrderStatusBadgeExternalLink } from "../components/order-status-badge/external-link";
import { OrderStatusBadgeDefault } from "../components/order-status-badge/default";
import { OrdersSelectedContext } from "../contexts";
import {
  faArrowUpArrowDown,
  faBarcode,
  faBookOpen,
  faBooks,
  faCalendarCircleUser,
  faCalendarClock,
  faPage,
  faTags,
} from "@fortawesome/sharp-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { orderType } from "../utils/filter-options";

const allowedStatusForBookPrinter = [
  OrderStatusEnum.waiting_print,
  OrderStatusEnum.waiting_cover_print,
  OrderStatusEnum.waiting_core_print,
  OrderStatusEnum.cover_print_finished,
  OrderStatusEnum.cover_print_requested,
  OrderStatusEnum.cover_printing,
  OrderStatusEnum.core_printing,
];

const allowedStatusForCoverPrinter = [
  OrderStatusEnum.waiting_print,
  OrderStatusEnum.waiting_cover_print,
  OrderStatusEnum.waiting_core_print,
  OrderStatusEnum.core_print_finished,
  OrderStatusEnum.core_print_requested,
  OrderStatusEnum.core_printing,
  OrderStatusEnum.cover_printing,
];

export const useColumns = () => {
  const { user } = useUserStore();
  const { rows } = useContext(OrdersSelectedContext);
  const [allRowsSelected, setAllRowsSelected] = useState(false);

  const checkboxDisabledByOrderSelected = useCallback(
    (status: OrderStatus[]) => {
      const firstOrderSelected = rows[0];
      if (!firstOrderSelected) return false;

      return status.every((s) => !firstOrderSelected.status.includes(s));
    },
    [rows]
  );

  const getStatusAllowed = (order: LoadOrdersList.Model) => {
    if (!user) return false;

    switch (user.type) {
      case "Finisher":
        return order.status.includes("finishing");
      case "Expeditor":
        return order.status.includes("shipping");
      case "BookPrinter":
        return (
          order.status.some((status) =>
            allowedStatusForBookPrinter.includes(status as OrderStatusEnum)
          ) &&
          !order.status.some(
            (status) =>
              status === "core_print_finished" ||
              status === "core_print_requested"
          )
        );
      case "CoverPrinter":
        return (
          order.status.some((status) =>
            allowedStatusForCoverPrinter.includes(status as OrderStatusEnum)
          ) &&
          !order.status.some(
            (status) =>
              status === "cover_print_finished" ||
              status === "cover_print_requested"
          )
        );
      default:
        return true;
    }
  };

  const getCheckedRows = (row: Row<LoadOrdersList.Model>) => {
    if (!user) return false;

    if (user.type === "Administrator") {
      return (
        !checkboxDisabledByOrderSelected(row.original.status) &&
        row.getIsSelected()
      );
    }

    const statusIsAllowed = getStatusAllowed(row.original);
    return statusIsAllowed && row.getIsSelected();
  };

  const getDisabledRows = (row: Row<LoadOrdersList.Model>) => {
    if (!user) return false;

    if (user.type === "Administrator") {
      return checkboxDisabledByOrderSelected(row.original.status);
    }

    const statusIsAllowed = getStatusAllowed(row.original);
    return (
      !statusIsAllowed || checkboxDisabledByOrderSelected(row.original.status)
    );
  };

  const columns: ColumnDef<LoadOrdersList.Model>[] = [
    {
      id: "select",
      header: ({ table }) => {
        return (
          <Checkbox
            checked={table.getIsAllPageRowsSelected() || allRowsSelected}
            onCheckedChange={(value) => {
              if (!user) return;
              setAllRowsSelected((prevState) => !prevState);

              const tableRows = table.getCoreRowModel().flatRows;

              if (user.type === "Administrator") {
                const filteredRows = tableRows.filter((row) => {
                  return row.original.status.some((status) => {

                    if (rows.length) {
                      return rows[0].status.includes(status);
                    }

                    return tableRows[0].original.status.includes(status)
                  });
                });

                filteredRows.forEach((row) => row.toggleSelected(!!value));

                return;
              }

              const firstRowWithStatusAllowed = tableRows.find((row) => {
                return getStatusAllowed(row.original);
              });

              const filteredRows = tableRows.filter((row) => {
                return row.original.status.some((status) => {
                  if (rows.length) {
                    return rows[0].status.includes(status);
                  }

                  return firstRowWithStatusAllowed?.original.status.includes(status)
                });
              });

              filteredRows.forEach((row) => row.toggleSelected(!!value));
            }}
            aria-label="select-all"
            className="translate-y-[2px]"
          />
        );
      },
      cell: ({ row }) => {
        return (
          <Checkbox
            checked={getCheckedRows(row)}
            onCheckedChange={(value) => {
              setAllRowsSelected((prevState) => {
                if (prevState) return false;
                return prevState;
              });
              row.toggleSelected(!!value);
            }}
            aria-label="select-order"
            disabled={getDisabledRows(row)}
            className="translate-y-[2px]"
          />
        );
      },
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "shipment",
      size: 100,
      header: () => (
        <DataTableOrder
          icon={<FontAwesomeIcon icon={faBarcode} />}
          accessor="shipment"
          title="Remessa"
        />
      ),
      cell: ({ row }) => (
        <div className="capitalize">{row.getValue("shipment")}</div>
      ),
    },
    {
      accessorKey: "publisher",
      size: 150,
      header: () => (
        <DataTableOrder
          icon={<FontAwesomeIcon icon={faBookOpen} />}
          accessor="publisher"
          hasSort={false}
          title="Solicitante"
        />
      ),
      cell: ({ row }) => {
        return <div className="capitalize">{row.original.publisher?.name}</div>;
      },
    },
    {
      accessorKey: "opened_at",
      size: 150,
      header: () => (
        <DataTableOrder
          icon={<FontAwesomeIcon icon={faCalendarCircleUser} />}
          accessor="opened_at"
          title="Abertura"
        />
      ),
      cell: ({ row }) => (
        <div className="capitalize">
          {formatDate(row.getValue("opened_at"))}
        </div>
      ),
    },
    {
      accessorKey: "due_date",
      size: 150,
      header: () => (
        <DataTableOrder
          icon={<FontAwesomeIcon icon={faCalendarClock} />}
          accessor="due_date"
          hasSort={false}
          title="Prazo"
        />
      ),
      cell: ({ row }) => (
        <div className="capitalize">{formatDate(row.getValue("due_date"))}</div>
      ),
    },
    {
      accessorKey: "pages_total",
      header: () => (
        <DataTableOrder
          icon={<FontAwesomeIcon icon={faPage} />}
          hasSort={false}
          accessor="pages_total"
          title="Páginas"
        />
      ),
      cell: ({ row }) => (
        <div className="capitalize">{row.original.pages_total}</div>
      ),
    },
    {
      accessorKey: "core_total",
      size: 130,
      header: () => (
        <DataTableOrder
          icon={<FontAwesomeIcon icon={faBooks} />}
          hasSort={false}
          accessor="core_total"
          title="Sku's/Itens"
        />
      ),
      cell: ({ row }) => (
        <div className="capitalize">{row.original.sku_total}/{row.original.core_total}</div>
      ),
    },
    {
      accessorKey: "type",
      size: 130,
      header: () => (
        <DataTableOrder
          icon={<FontAwesomeIcon icon={faTags} />}
          accessor="type"
          title="Tipo"
        />
      ),
      cell: ({ row }) => (
        <div className="capitalize">
          {orderType[row.getValue("type") as keyof typeof orderType]}
        </div>
      ),
    },
    {
      accessorKey: "status",
      size: 160,
      header: () => (
        <DataTableOrder
          icon={<FontAwesomeIcon icon={faArrowUpArrowDown} />}
          accessor="status"
          hasSort={false}
          title="Status"
        />
      ),
      cell: ({ row }) => {
        return (
          <div className="flex gap-1 flex-col">
            {row.original.status.map((status, idx) => (
              <React.Fragment key={idx}>
                {status === "shipping" || status === "finishing" ? (
                  <OrderStatusBadgeExternalLink
                    status={status}
                    variant={status}
                    shipment={row.original.shipment}
                  />
                ) : (
                  //@ts-ignore
                  <OrderStatusBadgeDefault status={status} variant={status} />
                )}
              </React.Fragment>
            ))}
          </div>
        );
      },
    },
  ];

  return { columns };
};
